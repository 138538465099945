<template>
  <div>
    <b-card title="Nueva Factura">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12" lg="7" class="border-right">
              <b-row class="mb-0-5">
                <b-col cols="12" md="12">
                  <b-form-group
                    label="Cliente:"
                    label-for="cliente"
                    label-cols="12"
                    label-cols-lg="2"
                  >
                    <v-select
                      ref="cliente"
                      id="cliente"
                      v-model="clienteItem"
                      label="razon_social_documento"
                      class="style-chooser select-size-sm"
                      placeholder="Buscar Cliente (Nombre, NIT, CI, CEX)"
                      :clearable="true"
                      :options="clienteItems"
                      @search="onSearchCliente"
                      @input="getClienteitem"
                    >
                      <template #no-options="{ search }">
                        <span v-if="search"
                          >lo siento, no hay opciones de coincidencia</span
                        >
                        <span v-else>Digite, para buscar al cliente</span>
                      </template>
                      <template #list-header="{ search }">
                        <li
                          style="text-align: center"
                          v-if="search && clienteItems.length == 0"
                          @click="getClienteitem(null)"
                          class="add-new-client-header cursor-pointer"
                        >
                          <span class="">Registrar Nuevo Cliente </span>
                        </li>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-row class="mb-0-5">
                    <b-col cols="12" md="6">
                      <validation-provider
                        #default="{ errors }"
                        name="Tipo de documento"
                        rules="required"
                      >
                        <b-form-group
                          label="Tipo Doc.:"
                          label-for="tipo_documento"
                          label-cols="12"
                          label-cols-lg="4"
                          :state="errors.length > 0 ? false : null"
                        >
                          <b-form-select
                            :disabled="disabledCliente"
                            id="tipo_documento"
                            size="sm"
                            v-model="formCliente.tipo_documento_id"
                            value-field="id"
                            text-field="descripcion"
                            :options="tipoDocumentoItems"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="8" md="4">
                      <b-form-group
                        label="Nro Doc:"
                        label-for="numero_documento"
                        label-cols="12"
                        label-cols-lg="3"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Numero de documento"
                          :rules="numeroDocumento"
                        >
                          <b-form-input
                            :disabled="disabledCliente"
                            size="sm"
                            id="numero_documento"
                            v-model="formCliente.numero_documento"
                            :state="errors.length > 0 ? false : null"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4" md="2">
                      <b-form-group
                        label="Comp:"
                        label-for="complemento"
                        label-cols="12"
                        label-cols-lg="4"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Complemento"
                          rules="max:5"
                        >
                          <b-form-input
                            :disabled="
                              disabledCliente ||
                              formCliente.tipo_documento_id != 1
                            "
                            size="sm"
                            id="complemento"
                            :formatter="textFormatter"
                            lazy-formatter
                            placeholder=""
                            v-model="formCliente.complemento"
                            :state="errors.length > 0 ? false : null"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0-5">
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Razon social:"
                        label-for="razon_social"
                        label-cols="12"
                        label-cols-lg="4"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Razon social"
                          rules="required"
                        >
                          <b-form-input
                            :disabled="disabledCliente"
                            size="sm"
                            id="razon_social"
                            :formatter="textFormatter"
                            lazy-formatter
                            v-model="formCliente.razon_social"
                            :state="errors.length > 0 ? false : null"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Correo:"
                        label-for="correo_electronico"
                        label-cols="12"
                        label-cols-lg="2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Correo electronico"
                          rules="email|max:100"
                        >
                          <b-form-input
                            :disabled="disabledCliente"
                            size="sm"
                            id="correo_electronico"
                            v-model="formCliente.correo_electronico"
                            :state="errors.length > 0 ? false : null"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" lg="5">
              <b-row>
                <b-col cols="12" md="12" class="mb-0-5">
                  <validation-provider
                    #default="{ errors }"
                    name="metodo_pago"
                    rules="required"
                  >
                    <b-form-group
                      label="Metodo pago:"
                      label-for="metodo_pago"
                      :state="errors.length > 0 ? false : null"
                      label-cols="12"
                      label-cols-lg="3"
                    >
                      <v-select
                        id="metodo_pago"
                        v-model="formapago"
                        label="descripcion"
                        class="style-chooser select-size-sm"
                        placeholder="Seleccione el metodo de pago"
                        :options="metodoPagoItems"
                        @input="obtenerFormaPago"
                      >
                      </v-select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="12" class="mb-0-5">
                  <validation-provider
                    #default="{ errors }"
                    name="Tarjeta de crédito"
                    :rules="ruleTarjeta"
                  >
                    <b-form-group
                      label="Nro tarjeta:"
                      label-for="numero_tarjeta"
                      label-cols="12"
                      label-cols-lg="3"
                    >
                      <b-form-input
                        id="numero_tarjeta"
                        :disabled="disableTarjeta"
                        v-model="factura.numeroTarjeta"
                        maxlength="16"
                        size="sm"
                        :state="errors.length > 0 ? false : null"
                        onClick="this.select();"
                        placeholder="0000 0000 0000 0000"
                        @input="tarjetaCredito"
                        :class="{ 'is-invalid': !!errors.length }"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr class="hr" />
          <b-row class="mb-0-5">
            <b-col cols="12" lg="7">
              <b-row>
                <b-col cols="6" lg="6">
                  <b-form-group
                    label="Código (Esc):"
                    label-for="codigo"
                    label-cols="12"
                    label-cols-lg="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MaximizeIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="codigo"
                        v-model="codigo"
                        @keyup.enter="buscarCodigo()"
                        onClick="this.select();"
                        size="sm"
                        placeholder="Digite o Escanee el código"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-button
                    class="btnBuscarArticulo"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="dark"
                    size="sm"
                    block
                    @click="$refs.ModalSelectArticuloRef.focusBuscarArticulo()"
                  >
                    BUSCAR ARTICULO (F9)
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col cols="12">
              <ag-grid-vue
                :gridOptions="gridOptions"
                class="ag-theme-balham ag-grid-tableFactura"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                @grid-ready="onGridReady"
                :rowData="factura.detalles"
                :suppressRowClickSelection="true"
                colResizeDefault="shift"
                :animateRows="false"
                :floatingFilter="false"
                :rowClassRules="rowClassRules"
                @rowClicked="onRowClicked($event)"
                :alwaysShowVerticalScroll="true"
                @first-data-rendered="onFirstDataRendered"
                :getRowStyle="getRowStyle"
                :pinnedBottomRowData="pinnedBottomRowData"
                @cell-value-changed="onCellValueChanged"
                @cell-key-down="onCellKeyDown"
              >
              </ag-grid-vue>
            </b-col>
          </b-row>

          <b-row align-h="end">
            <b-col cols="12" md="4">
              <b-form-group style="text-align: end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="dark"
                  class="mr-1"
                  @click.prevent="validar"
                >
                  REALIZAR FACTURA
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-overlay
        :show="submitted"
        no-wrap
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      />
      <ModalSelectArticulo
        ref="ModalSelectArticuloRef"
        esServicio="1"
        :ocultarCostoColumna="$hascan('venta-ver_costo')"
        @selectItemArticulo="selectItemArticulo"
        :giroComercialId="mixing.giroComercialId"
      />
      <ModalPdf
        :pdf="pdf"
        :urlSiat="urlSiat"
        :facturaId="facturaId"
        :pdfBase64="pdfBase64"
        :accionModalPdf="accionModalPdf"
        @cerrarModalPdf="cerrarModalPdf"
      />
    </b-card>
  </div>
</template>
  <script>
import ModalPdf from "@/modules/components/ModalPdf.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FacturacionServices from "@/modules/configuracion-sin/services/index";
import ProductoServices from "@/modules/administracion/producto/services/index";
import { ClienteService } from "@/modules/administracion/cliente_proveedor/services/index";
import MetodoPagoServices from "@/modules/configuracion-sin/services/metodoPago";
import TipoDocumentoService from "@/modules/configuracion/tipo-documento/services/index";
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardGroup,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BFormSpinbutton,
  BFormRadio,
  BFormCheckbox,
  BTable,
  BInputGroup,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BBadge,
  BForm,
  BOverlay,
  BImg,
  BInputGroupAppend,
  VBTooltip,
  BInputGroupPrepend,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { required } from "@validations";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AgGridVue } from "ag-grid-vue";
import ModalSelectArticulo from "@/modules/components/ModalSelectArticulo.vue";
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardGroup,
    BCardBody,
    BCardTitle,
    BCardFooter,
    vSelect,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    Cleave,
    BFormSpinbutton,
    BFormRadio,
    BFormCheckbox,
    BTable,
    VuePerfectScrollbar,
    BInputGroup,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BOverlay,
    BImg,
    BInputGroupAppend,
    VBTooltip,
    AgGridVue,
    BInputGroupPrepend,
    ModalSelectArticulo,
    ModalPdf,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      // pdf facturacion
      accionModalPdf: false,
      pdf: "",
      pdfBase64: "",
      urlSiat: "",
      facturaId: "",
      // modal articulo
      item: [],
      // table
      required,
      loading: false,
      metodoPagoItems: [],
      formapago: null,

      // grid
      gridOptions: {
        localeText: {
          noRowsToShow: "",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,
      gridApi: null,
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: false,
      },
      columnDefs: [
        {
          headerName: "CANTIDAD",
          field: "cantidad",
          type: "numericColumn",
          filter: false,
          valueFormatter: cantidadNumberFormatter,
          valueParser: cantidadParser,
          minWidth: 100,
          maxWidth: 100,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "CODIGO",
          field: "codigoProducto",
          filter: false,
          minWidth: 220,
          maxWidth: 220,
        },
        {
          headerName: "PRODUCTO",
          cellRenderer: function (param) {
            if (param.data.esServicio) {
              return `${param.data.descripcion} <span class="badge badge-warning">SERV.</span>`;
            } else {
              return param.data.descripcion;
            }
          },

          filter: false,
          minWidth: 200,
        },
        {
          headerName: "NRO SERIE",
          field: "numeroSerie",
          filter: false,
          minWidth: 120,
          maxWidth: 120,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "NRO IMEI",
          field: "numeroImei",
          filter: false,
          minWidth: 120,
          maxWidth: 120,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "PRECIO",
          field: "precioUnitario",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: precioNumberFormatter,
          valueParser: precioParser,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true; //true
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "DESCUENTO",
          field: "montoDescuento",
          type: "numericColumn",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
          valueFormatter: descuentoNumberFormatter,
          valueParser: descuentoParser,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true; //true
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "SUBTOTAL",
          field: "subTotal",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: numberFormatter,
          editable: function (param) {
            if (param.node.rowPinned) {
              if (param.node.data.edit) {
                return true;
              }
              return false;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
          valueGetter: (param) => {
            if (!param.node.rowPinned) {
              param.data.subTotal =
                param.data.cantidad * param.data.precioUnitario -
                param.data.montoDescuento;
              return param.data.subTotal;
            }
            return param.node.data.subTotal;
          },
        },
        {
          headerName: "",
          filter: false,
          minWidth: 50,
          maxWidth: 50,
          cellRenderer: function (param) {
            if (param.node.rowPinned) {
              return undefined;
            } else {
              return `<button type="button" data-action-type="remove" class="btn btn-flat-danger btn-sm">X</button>`;
            }
          },
        },
      ],
      rowClassRules: {
        "row-red": "(data.stock < data.cantidad) && (data.esServicio)==0",
      },
      // end grid
      submitted: false,
      // transaccion
      transaccion: {
        cliente_proveedor_id: null,
        usuario_id: null,
        // codigo_metodo_pago: "",
        metodo_pago: "",
        metodo_pago_id: null,
        tipo_transaccion_id: 2,
        nro_tarjeta: null,
        subtotal: 0,
        descuento: 0,
        total: 0,
        razon_social: "",
        numero_documento: "",
        detalleTransaccion: [],
        sede_id: "",
        efectivo: "0.00",
        tarjeta: "0.00",
        credito: "0.00",
        transferencia_bancaria: "0.00",
        cambio: "0.00",
        otro: "0.00",
        banco_id: null,
        recibo: "",
        comprobante_pago: "",
        iva: 0,
        total_impuesto: 0,
        total_pagado: 0,
        fecha: null,
        observaciones: "",
      },
      tipoDocumentoItems: [],
      codigoMetodoPago: null,

      // variables de clientes
      nro_documento_complemento: "",
      cliente: "",
      disabledCliente: true,
      formCliente: {
        id: null,
        tipo_documento_id: null,
        numero_documento: "",
        complemento: "",
        razon_social: "",
        direccion: "",
        ciudad: "",
        zona: "",
        correo_electronico: "",
        celular: "",
        nro_documento_complemento: "",
        es_verificado: 0,
      },
      disableGitcard: false,
      disableTarjeta: true,
      ruleTarjeta: "",
      errores: [],
      codigo: "",
      transaccion_id: null,
      clienteItems: [],
      clienteItem: null,
      debounce: null,

      disableGitcard: false,

      factura: {
        cafc: null,
        // clientes
        codigoTipoDocumentoIdentidad: 0,
        numeroDocumento: "",
        codigoCliente: "",
        nombreRazonSocial: "",
        complemento: null,
        codigoDocumentoSector: 1,
        codigoExcepcion: 0,
        codigoMetodoPago: 0,
        codigoMoneda: 1,
        codigoPuntoVenta: 0,
        codigoSucursal: 0,
        descuentoAdicional: 0,
        detalles: [],
        email: "",
        fechaHora: null,
        leyenda: "",
        montoGiftCard: 0,
        montoTotalSujetoIva: 0,
        montoTotal: 0,
        montoTotalMoneda: 0,
        numeroDocumentoCliente: "",
        numeroFacturaCafc: 0,
        numeroTarjeta: null,
        online: true,
        tipoCambio: 1,
        tipoEmision: 1,
        tipoFactura: 1,
        usuario: "",
        subTotal: 0,
        enviarCorreo: true,
        // formato de hoja
        tamanio: 1,
        // nuevos
        documentoOrigen: null,
      },
    };
  },
  created: function () {
    this.getRowStyle = (params) => {
      if (params.node.rowPinned) {
        if (params.node.data.bold) {
          return { "font-weight": "bold" };
        }
      }
    };
    window.addEventListener("keyup", this.onkey);
  },
  destroyed: function () {
    window.removeEventListener("keyup", this.onkey);
  },
  watch: {
    "formCliente.tipo_documento_id": function () {
      if (this.formCliente.tipo_documento_id != 1) {
        if (this.formCliente.tipo_documento_id != "") {
          this.formCliente.complemento = "";
        }
      }
      this.errores.tipo_documento_id = [];
    },
    "formCliente.numero_documento": function () {
      this.errores.numero_documento = [];
    },
    "formCliente.complemento": function () {
      this.errores.complemento = [];
    },
    "formCliente.correo_electronico": function () {
      this.errores.correo_electronico = [];
    },
    "formCliente.razon_social": function () {
      this.errores.razon_social = [];
    },
  },
  computed: {
    pinnedBottomRowData() {
      return [
        {
          montoDescuento: "SUBTOTAL Bs",
          subTotal: this.calcularSubTotal(),
        },
        {
          montoDescuento: "DESCUENTO Bs",
          edit: true,
          subTotal: this.factura.descuentoAdicional,
          key: "descuentoAdicional",
        },
        {
          montoDescuento: "TOTAL Bs",
          subTotal: this.calcularTotal(),
          bold: true,
        },
        {
          montoDescuento: "MONTO GIFT CARD Bs",
          edit: this.disableGitcard,
          subTotal: this.factura.montoGiftCard,
          key: "montoGiftCard",
        },
        {
          montoDescuento: "IMPORTE BASE CF Bs",
          subTotal: this.factura.montoTotalSujetoIva,
          bold: true,
          hide: true,
        },
      ];
    },
    numeroDocumento() {
      if (
        this.formCliente.tipo_documento_id == 1 ||
        this.formCliente.tipo_documento_id == 5
      ) {
        return "required|integer|max:20";
      } else {
        return "required|max:20";
      }
    },
  },
  methods: {
    onkey(event) {
      if (event.key == "F8") {
        setTimeout(() => {
          this.$nextTick(() => {
            let input = this.$refs.cliente.$el.querySelector("input");
            input.focus();
          });
        }, 100);
      }
      if (event.key == "F9") {
        this.$refs.ModalSelectArticuloRef.focusBuscarArticulo();
      }
      if (event.key == "Escape") {
        document.getElementById("codigo").focus();
        document.getElementById("codigo").select();
      }
    },
    onSearchCliente(search, loading) {
      if (search.length) {
        loading(true);
        clearTimeout(this.debounce);
        this.debounce = setTimeout(async () => {
          try {
            const response = await ClienteService.buscar({
              q: search,
            });
            const data = response.data.map((g) => ({
              ...g,
              razon_social_documento:
                g.numero_documento + " - " + g.razon_social,
            }));
            this.clienteItems = data;
            loading(false);
          } catch (err) {
            console.log(err);
            loading(false);
          }
        }, 350);
      } else {
        loading(false);
        this.clienteItems = [];
      }
    },
    getClienteitem(item) {
      if (item == null) {
        setTimeout(() => {
          this.$nextTick(() => {
            document.getElementById("tipo_documento").focus();
          });
        }, 100);
        this.clienteItem = null;
        this.cliente = [];
        this.disabledCliente = false;
        this.formCliente = {
          id: null,
          tipo_documento_id: null,
          numero_documento: "",
          complemento: "",
          razon_social: "",
          correo_electronico: "",
          nro_documento_complemento: "",
          es_verificado: 0,
        };
      } else {
        this.disabledCliente = true;
        this.cliente = item;
        this.getCliente();
      }
    },
    // cliente
    async buscarDocumento() {
      try {
        const response = await ClienteService.findOne(
          this.nro_documento_complemento
        );
        this.disabledCliente = true;
        this.cliente = response.data;
        this.getCliente();
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ADVERTENCIA!",
          variant: "warning",
          oaster: "b-toaster-top-center",
          solid: false,
        });
        this.nro_documento_complemento;
        this.disabledCliente = false;
        this.formCliente = {
          id: null,
          tipo_documento_id: null,
          numero_documento: this.nro_documento_complemento,
          complemento: "",
          razon_social: "",
          correo_electronico: "",
          nro_documento_complemento: "",
          es_verificado: 0,
        };
      }
    },
    getCliente() {
      let complemento = this.cliente.complemento
        ? this.cliente.complemento
        : null;
      this.factura.codigoTipoDocumentoIdentidad =
        this.cliente.tipo_documento_id;
      this.factura.numeroDocumento = this.cliente.numero_documento;
      this.factura.numeroDocumentoCliente = this.cliente.numero_documento;
      this.factura.codigoCliente = this.cliente.id;
      this.factura.nombreRazonSocial = this.cliente.razon_social;
      this.factura.complemento = complemento;
      this.factura.codigoExcepcion = this.cliente.es_verificado ? 0 : 1;
      this.factura.email = this.cliente.correo_electronico;
      let enviarCorreoElectronico = false;
      if (this.cliente.correo_electronico) {
        enviarCorreoElectronico = true;
      }
      this.factura.enviarCorreo = enviarCorreoElectronico;
      this.formCliente = {
        id: this.cliente.id,
        tipo_documento_id: this.cliente.tipo_documento_id,
        numero_documento: this.cliente.numero_documento,
        complemento: complemento,
        razon_social: this.cliente.razon_social,
        correo_electronico: this.cliente.correo_electronico,
        nro_documento_complemento: this.nro_documento_complemento,
        es_verificado: this.cliente.es_verificado ? 1 : 0,
      };
    },
    async getFormCliente() {
      this.formCliente.id = null;
      this.formCliente.nro_documento_complemento =
        this.formCliente.numero_documento + this.formCliente.complemento;
      // comentar will
      if (this.formCliente.tipo_documento_id == 5) {
        await this.verificarNit();
      }
    },
    async registrarCliente() {
      await this.getFormCliente();
      try {
        const response = await ClienteService.store(this.formCliente);
        this.cliente = response.data.cliente_proveedor;
        await this.getCliente();
        this.disabledCliente = true;
        await this.registrar();
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          oaster: "b-toaster-top-center",
          solid: false,
        });
        this.disabledCliente = false;
        this.submitted = false;
        this.errores = err.response.data.errors;
        if (this.errores.numero_documento !== undefined) {
          this.$bvToast.toast(this.errores.numero_documento[0], {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
        if (this.errores.complemento !== undefined) {
          this.$bvToast.toast(this.errores.complemento[0], {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
        if (this.errores.razon_social !== undefined) {
          this.$bvToast.toast(this.errores.razon_social[0], {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
        if (this.errores.correo_electronico !== undefined) {
          this.$bvToast.toast(this.errores.correo_electronico[0], {
            title: "ERROR!",
            variant: "danger",
            solid: false,
          });
        }
      }
    },
    // verificar nit
    // comentar will
    async verificarNit() {
      try {
        const response = await FacturacionServices.verficarNit(
          this.formCliente.numero_documento
        );
        if (response.data.transaccion) {
          this.formCliente.es_verificado = 1;
          this.$bvToast.toast("NIT Verificado", {
            title: "EXITO!",
            variant: "success",
            solid: false,
          });
        } else {
          this.$bvToast.toast("No se pudo verificar el NIT", {
            title: "ADVERTENCIA!",
            variant: "danger",
            solid: false,
          });
          this.formCliente.es_verificado = 0;
        }
      } catch (err) {
        this.$bvToast.toast("Error no hay comunicacion con siat", {
          title: "ADVERTENCIA!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.factura.detalles.length > 0) {
            this.submitted = true;
            this.registrarVenta();
          } else {
            this.$bvToast.toast(" AGREGAR POR LO MENOS UN ARTICULO. ", {
              title: "ERROR!",
              variant: "danger",
              toaster: "b-toaster-top-center",
              solid: false,
            });
          }
        } else {
          this.$bvToast.toast(" COMPLETE LOS CAMPOS. ", {
            title: "ERROR!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: false,
          });
        }
      });
    },
    // registrar la venta
    async registrarVenta() {
      if (
        this.formCliente.tipo_documento_id == 5 &&
        !this.formCliente.es_verificado
      ) {
        // await this.verificarNit();
        await this.registrar();
      } else {
        await this.registrar();
      }
    },
    async registrar() {
      try {
        const response = await FacturacionServices.store(this.factura);
        this.submitted = false;
        this.limpiarForm();
        this.errores = [];
        this.$bvToast.toast(" LA FACTURA SE GENERO CORRECTAMENTE. ", {
          title: "EXITO!",
          variant: "success",
          solid: false,
        });
        this.pdfBase64 = response.data.data.facturaBase64;
        this.pdf =
          "data:application/pdf;base64," +
          response.data.data.facturaBase64 +
          "#toolbar=0";
        this.accionModalPdf = true;
        this.submitted = false;
        this.urlSiat = response.data.data.venta.urlSiat;
        this.facturaId = response.data.data.venta.id;
      } catch (err) {
        console.log("error", err.response.data);
        this.submitted = false;
        const data = err.response.data;
        const mensaje = data.mensaje;
        const errors = data.errors;
        let detalle = "";
        if (mensaje || data.detalles) {
          detalle = `Error ${mensaje}`;
        } else if (errors) {
          const h = this.$createElement;
          detalle = Object.entries(errors).map(([key, value]) => {
            return h("p", { class: ["mb-0"] }, [
              h("strong", key + ":"),
              " " + value,
              h("br"),
            ]);
          });
        } else {
          detalle = `${data[0].codigo} - ${data[0].descripcion}`;
        }
        this.$bvToast.toast(detalle, {
          title: "ERROR!",
          autoHideDelay: 7000,
          variant: "danger",
          toaster: "b-toaster-top-full",
          solid: false,
          html: true,
        });
      }
    },
    // remover de grid
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        let rowIndex = e.node.id;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "view":
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },
    remover(index) {
      this.factura.detalles.splice(index, 1);
    },
    // calculos de totales
    calcularSubTotal() {
      let resultado = 0;
      for (let index = 0; index < this.factura.detalles.length; index++) {
        resultado = resultado + this.factura.detalles[index].subTotal;
      }
      resultado = resultado.toFixed(2);
      this.factura.subTotal = parseFloat(resultado);
      return resultado;
    },
    calcularTotal() {
      let resultado = 0;
      resultado =
        parseFloat(this.factura.subTotal) -
        parseFloat(this.factura.descuentoAdicional);
      resultado = resultado.toFixed(2);
      this.factura.montoTotal = parseFloat(resultado);
      this.factura.montoTotalSujetoIva =
        parseFloat(resultado) - parseFloat(this.factura.montoGiftCard);
      this.factura.montoTotalMoneda = parseFloat(resultado);
      return resultado;
    },
    onCellValueChanged(event) {
      if (event.data.key == "descuentoAdicional") {
        this.factura.descuentoAdicional = event.data.subTotal;
      }
      if (event.data.key == "ajusteAfectacionIva") {
        this.factura.ajusteAfectacionIva = event.data.subTotal;
      }
      if (event.data.key == "montoGiftCard") {
        this.factura.montoGiftCard = event.data.subTotal;
      }
    },
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        let rowIndex = e.node.id;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "view":
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },
    // TARJETA
    tarjetaCredito(value) {
      if (value.length == 4) {
        this.factura.numeroTarjeta = value + "00000000";
      }
    },
    // grid
    onGridReady(params) {
      params.api.sizeColumnsToFit();
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    // FORMATOS DE TEXTO EN MAUSCULAS Y DECIMALES
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    // limpiar formulario
    limpiarForm() {
      this.factura = {
        cafc: null,
        codigoTipoDocumentoIdentidad: 0,
        numeroDocumento: "",
        codigoCliente: "",
        nombreRazonSocial: "",
        complemento: null,
        codigoDocumentoSector: 1,
        codigoExcepcion: 0,
        codigoMetodoPago: 0,
        codigoMoneda: 1,
        codigoPuntoVenta: 0,
        codigoSucursal: 0,
        descuentoAdicional: 0,
        detalles: [],
        email: "",
        fechaHora: null,
        leyenda: "",
        montoGiftCard: 0,
        montoTotalSujetoIva: 0,
        montoTotal: 0,
        montoTotalMoneda: 0,
        numeroDocumentoCliente: "",
        numeroFacturaCafc: 0,
        numeroTarjeta: null,
        online: true,
        tipoCambio: 1,
        tipoEmision: 1,
        tipoFactura: 1,
        usuario: this.mixing.nombre,
        subTotal: 0,
        enviarCorreo: true,
        // formato de hoja
        tamanio: 1,
        // nuevos
        documentoOrigen: null,
      };

      this.formapago = null;
      this.clienteItem = null;
      this.codigoMetodoPago = null;
      this.cliente = "";
      this.nro_documento_complemento = "";
      this.disabledCliente = true;
      this.disableGitcard = true;
      this.codigo = "";

      this.formCliente = {
        id: null,
        tipo_documento_id: null,
        numero_documento: "",
        complemento: "",
        razon_social: "",
        correo_electronico: "",
        nro_documento_complemento: "",
        es_verificado: 0,
      };
      this.$refs.ModalSelectArticuloRef.focusBuscarArticuloNuevo();
      this.$refs.simpleRules.reset();
    },
    // llamadas ala api de sifacturo
    async getDocumentoIdentidad() {
      try {
        const response = await TipoDocumentoService.get({ estado: 1 });
        this.tipoDocumentoItems = response.data;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async getMetodosPago() {
      try {
        const response = await MetodoPagoServices.get({});
        this.metodoPagoItems = response.data;
        this.factura.codigoMetodoPago = null;
      } catch (err) {
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    obtenerFormaPago() {
      if (this.formapago == null) {
        this.formapago = null;
        this.factura.codigoMetodoPago = 0;
        this.factura.numeroTarjeta = null;
        this.ruleTarjeta = "";
      } else {
        this.factura.codigoMetodoPago = this.formapago.id;
        this.factura.numeroTarjeta = null;

        this.factura.montoGiftCard = 0;

        if (this.formapago.descripcion.includes("TARJETA")) {
          this.ruleTarjeta = "required|min:16|credit_card";
          this.disableTarjeta = false;
        } else {
          this.ruleTarjeta = "";
          this.disableTarjeta = true;
        }

        if (this.formapago.descripcion.includes("GIFT")) {
          this.disableGitcard = true;
        } else {
          this.disableGitcard = false;
        }
      }
    },
    // codigo de barra articulos
    async buscarCodigo() {
      if (this.codigo.length > 0) {
        try {
          const response = await ProductoServices.findOneCodigo(
            this.codigo,
            this.mixing.sede_id
          );
          this.selectItemArticulo(response.data, false);
        } catch (err) {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "ADVERTENCIA!",
            variant: "warning",
            oaster: "b-toaster-top-center",
            solid: false,
          });
          document.getElementById("codigo").focus();
          document.getElementById("codigo").select();
        }
      }
    },
    // encuentra articulo
    encuentraArticulo(id, estado, es_servicio) {
      var sw = false;
      for (let i = 0; i < this.factura.detalles.length; i++) {
        if (this.factura.detalles[i].id == id) {
          this.factura.detalles[i].cantidad++;
          sw = true;
          if (estado) {
            setTimeout(() => {
              this.focusAgridCantidad(i);
            }, 120);
          } else {
            setTimeout(() => {
              document.getElementById("codigo").focus();
              document.getElementById("codigo").select();
            }, 120);
            this.gridApi.redrawRows();
          }
        }
      }
      return sw;
    },
    // Emit de select articulo
    selectItemArticulo(producto, estado) {
      if (producto) {
        setTimeout(() => {
          this.agregarItem(producto, estado);
        }, 100);
      } else {
        this.$bvToast.toast(" POR FAVOR SELECCIONE UN ARTICULO ", {
          title: "ADVERTENCIA!",
          variant: "warning",
          toaster: "b-toaster-top-center",
          solid: false,
        });
      }
    },
    agregarItem(producto, estado) {
      if (!this.encuentraArticulo(producto.id, estado, producto.es_servicio)) {
        if (producto.codigo_actividad != "" && producto.codigo_sin != null) {
          const cantidad = 1;
          const precio = producto.precio_unitario;
          const subtotal = precio * cantidad;
          this.factura.detalles.push({
            id: producto.id,
            actividadEconomica: producto.codigo_actividad,
            codigoProductoSin: producto.codigo_sin,
            codigoProducto: producto.codigo,
            descripcion: producto.descripcion,
            cantidad: parseFloat(cantidad),
            unidadMedida: producto.unidad_id,
            precioUnitario: precio,
            montoDescuento: 0,
            numeroSerie: "",
            numeroImei: "",
            subTotal: subtotal,
          });
          if (estado) {
            setTimeout(() => {
              this.focusAgrid();
            }, 120);
          } else {
            setTimeout(() => {
              document.getElementById("codigo").focus();
              document.getElementById("codigo").select();
            }, 120);
            this.gridApi.redrawRows();
          }
        } else {
          this.$bvToast.toast(
            "Falta el código de actividad y/o el código SIN",
            {
              title: "ERROR!",
              variant: "danger",
              solid: false,
            }
          );
        }
      }
    },
    focusAgrid() {
      const cellrows = this.gridApi.getDisplayedRowCount();
      this.gridApi.setFocusedCell(cellrows - 1, "cantidad");
      this.gridApi.startEditingCell({
        rowIndex: cellrows - 1,
        colKey: "cantidad",
      });
    },
    focusAgridCantidad(i) {
      this.gridApi.setFocusedCell(i, "cantidad");
      this.gridApi.startEditingCell({
        rowIndex: i,
        colKey: "cantidad",
      });
    },
    // key pres
    onCellKeyDown(params) {
      if (params.event) {
        if (params.event.key == "Enter") {
          setTimeout(() => {
            document.getElementById("codigo").focus();
          }, 200);
        }
      }
    },
    cerrarModalPdf() {
      this.accionModalPdf = false;
      this.pdf = "";
      this.pdfBase64 = "";
      this.urlSiat = "";
      this.facturaId = "";
      this.$emit("venta-registrada");
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.getDocumentoIdentidad();
    this.getMetodosPago();
    this.$refs.ModalSelectArticuloRef.focusBuscarArticuloNuevo();
    this.factura.usuario = this.mixing.nombre;
    this.factura.tamanio = this.mixing.config.tamanio_factura;
  },
};
function numberFormatter(params) {
  return Number(params.value).toFixed(2);
}
function cantidadNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function precioNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function descuentoNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function cantidadParser(params) {
  //   if (!isNaN(params.newValue) && params.newValue >= 0) {
  //     if (!params.data.esServicio) {
  //       if (parseFloat(params.newValue) > parseFloat(params.data.stock)) {
  //         return params.data.cantidad;
  //       } else {
  //         return Number(params.newValue).toFixed(2);
  //       }
  //     } else {
  //       return Number(params.newValue).toFixed(2);
  //     }
  //   } else {
  //     return params.data.cantidad;
  //   }
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.cantidad;
  }
}
function precioParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.precioUnitario;
  }
}
function descuentoParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.montoDescuento;
  }
}
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/components/agGridStyle.scss";
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
.table-active > td {
  background-color: #ff7676 !important;
  color: white;
}
.table-active > td > button {
  background-color: #ff7676 !important;
  color: white;
}
.b-table-sticky-header {
  overflow-y: auto;
  max-height: calc(var(--vh, 1vh) * 100 - 26.3rem);
}
.my-0-5 {
  margin: 0.5rem !important;
  margin-left: 0px !important;
}
#card-pv {
  .card-body {
    padding: 0.8rem;
  }
}
.hr {
  margin-top: 0.1rem;
  margin-bottom: 0.25rem;
}
.hr1 {
  margin-top: 0.4rem;
  margin-bottom: 1rem;
}
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}
[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
.box {
  height: 9rem;
}
.box img {
  width: 100%;
  height: 100%;
}
.box1 img {
  object-fit: cover;
}
.custom-select:disabled {
  color: #6e6b7b;
  font-weight: 500;
}
.ag-theme-balham .row-red {
  color: #ea5455 !important;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $primary;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}

.btnBuscarArticulo {
  margin-top: 2rem;
}

@media (min-width: 992px) {
  .btnBuscarArticulo {
    margin-top: 0;
  }
}
</style>